import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../styles/main.less'

import Header from './header'
import Footer from './footer'

const Layout = ({ children, scrollToSection }) => {

	useEffect(() => {    
		if (!!scrollToSection) {
			const scrollToDiv = document.getElementById(scrollToSection)

			setTimeout(() => {
				!!scrollToDiv && scrollToDiv.scrollIntoView()
				window.scrollBy(0, -70)
			}, 500)
		}
    });

	return (
	  <Fragment>
	    <Header />
	    <div className="content">{children}</div>
	    <Footer />
	  </Fragment>
	)
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
