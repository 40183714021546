import React, { Component, Fragment } from 'react'
import { Container, Collapse, Navbar, Nav, NavItem } from 'reactstrap'

import { HamburgerButton } from 'react-hamburger-button'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import logo from '../images/logo.png'

export default class Header extends Component {
  state = {
    isOpen: false,
    isTop: true,
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  handleScroll = () => {
    const isTop = window.scrollY < 600 //document.find bottom of map image px
    if (isTop !== this.state.isTop) {
      this.setState({ isTop })
    }
  }

  closeNav = () => {
    this.setState({
      isOpen: false,
    })
  }

  render() {
    const { isTop, isOpen } = this.state
    return (
      <Fragment>
        <div id="navbar-invisible" className={`${isTop ? 'top' : 'bottom'}`} />
        <div className={`navbar-container ${isTop ? 'top' : 'bottom'}`}>
          <Container>
            <Navbar light expand="md">
              <div className="navbar-brand">
                <AnchorLink href="#navbar-invisible" onClick={this.closeNav}>
                  <img src={logo} alt="Topos" />
                </AnchorLink>
              </div>
              <div className="hamburger">
                <HamburgerButton
                  open={this.state.isOpen}
                  onClick={this.toggle}
                  width={22}
                  height={15}
                  strokeWidth={1}
                  color="#141414"
                  animationDuration={0.5}
                />
              </div>
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <AnchorLink
                      offset={isOpen ? '100' : '50'}
                      href="#about"
                      onClick={this.closeNav}
                    >
                      About
                    </AnchorLink>
                  </NavItem>
                  <NavItem>
                    <AnchorLink
                      offset={isOpen ? '100' : '50'}
                      href="#products"
                      onClick={this.closeNav}
                    >
                      Products
                    </AnchorLink>
                  </NavItem>
                  <NavItem>
                    <AnchorLink
                      offset={isOpen ? '100' : '50'}
                      href="#jobs"
                      onClick={this.closeNav}
                    >
                      Jobs
                    </AnchorLink>
                  </NavItem>
                  <NavItem>
                    <AnchorLink
                      offset={isOpen ? '100' : '50'}
                      href="#blog"
                      onClick={this.closeNav}
                    >
                      Blog
                    </AnchorLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </Container>
        </div>
      </Fragment>
    )
  }
}
