import React from 'react'
import {
  Form,
  FormGroup,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from 'reactstrap'

const Signup = () => (
  <Form
    action="https://topos.us15.list-manage.com/subscribe/post?u=1f318b497f65fd73633652591&amp;id=e3b711e839"
    method="POST"
    target="_blank"
    className="signup"
  >
    <FormGroup>
      <InputGroup>
        <Input
          type="email"
          name="EMAIL"
          placeholder="Get email updates"
          id="mce-EMAIL"
          required
        />
        <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
          <input
            type="text"
            name="b_1f318b497f65fd73633652591_e3b711e839"
            tabIndex="-1"
          />
        </div>

        <InputGroupAddon addonType="append">
          <Button type="submit" name="subscribe" id="mc-embedded-subscribe">
            sign up
          </Button>
        </InputGroupAddon>
      </InputGroup>

      <div
        style={{ position: 'absolute', left: '-5000px' }}
        aria-hidden="true"
        aria-label="Please leave the following three fields empty"
      >
        <input
          type="email"
          name="b_email"
          tabIndex="-1"
          placeholder="youremail@gmail.com"
          id="b_email"
        />
      </div>
    </FormGroup>
  </Form>
)

export default Signup
